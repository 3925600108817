let backendUrl:string;
if (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'test'){
  backendUrl = "http://localhost:8080"
  // backendUrl = "https://mainnet.backend.mesprotocol.com"
}else{
  backendUrl = "https://backend.mesprotocol.com"
}
export const backendPrefix = backendUrl;


export const zkSyncProvider = 'https://zksync2-testnet.zksync.dev'
// export const zkSyncProvider = "http://localhost:3050"
export const walletPk = '0d0527f08a8688cc0a356f49f312164dc7cb1458be808a61828b3fe6fab0c796'
